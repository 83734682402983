<script setup>
import { computed, onMounted, ref } from "vue";
import SearchBox from "@/components/layout/RE/policies/searchbox.vue";
import createNewPolicyModal from "./createNewPolicyModal.vue";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
import { useStore } from "vuex";

const store = useStore();
const allPolicyTypes = [
  {
    title: "Interview",
  },
  {
    title: "Induction",
  },
  {
    title: "Job Specification",
  },
  {
    title: "Employee Handbook",
  },
  {
    title: "IT Policies",
  },
  {
    title: "Appraisal",
  },
];
const selectedPolicyType = ref("");
const showModal = ref(false);
const showDropDown = ref(false);
const loading = ref(false);
const dropdownOptions = [
  {
    type: "link",
    linkName: "re-view-interview-policy",
    paramName: "interviewPolicyId",
    label: "View",
  },
  {
    type: "action",
    label: "Delete",
    action: () => {
      console.log("i am called");
    },
  },
];
const tableHeaders = [
  { title: "Date", prop: "created_at", id: "date" },
  {
    title: "Roles",
    prop: "roles",
    id: "role",
  },
  {
    title: "Type",
    prop: "type",
    id: "type",
  },

  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];
const allPolicies = computed(() => {
  return store.getters["RePolicies/getAllDifferentPolicies"];
});
onMounted(async () => {
  await store.dispatch("admin/loadRoles", 1);
  loading.value = true;
  try {
    await store.dispatch("RePolicies/getAllDifferentPolicies", "it-policies");
  } catch (error) {
    console.log(error);
    loading.value = false;
    this.error = error.message || "Something went wrong!";
  }
  loading.value = false;
});

const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});
const rolesDefiner = (arr) => {
  return arr.map((item) => item.name).join(", ");
};
</script>
<template>
  <div class="container mx-auto px-4 mt-10">
    <div class="flex gap-4 items-center">
      <div class="w-96">
        <div
          @click="showModal = true"
          class="
            bg-white
            w-1/2
            py-4
            cursor-pointer
            hover:shadow-sm
            text-center
            mx-auto
            shadow-md
            rounded-xl
          "
        >
          <div class="">
            <span class="text-5xl material-icons"> post_add </span>
          </div>
          New IT Policy
        </div>
      </div>
      <div class="flex-grow">
        <SearchBox
          :secondaryOptions="getRoles"
          :layout="'horizontal'"
          class="w-full shadow-md px-5"
          title="Policy Search"
        />
      </div>
      <div class="w-64">
        <div
          class="
            bg-white
            py-4
            cursor-pointer
            hover:shadow-md
            mx-auto
            shadow-md
            rounded-xl
            p-5
          "
        >
          <p class="font-medium text-xl">Select Policy Type</p>
          <div
            class="form-check mr-3"
            v-for="item in allPolicyTypes"
            :key="item.title"
          >
            <input
              v-model="selectedPolicyType"
              class="
                form-check-input
                appearance-none
                rounded-full
                h-4
                w-4
                border border-gray-300
                bg-white
                checked:bg-blue-600 checked:border-blue-600
                focus:outline-none
                transition
                duration-200
                mt-1
                align-top
                bg-no-repeat bg-center bg-contain
                float-left
                mr-1
                cursor-pointer
              "
              type="radio"
              name="policyType"
              :value="item.title"
              :id="item.title"
            />
            <label
              class="capitalize inline-block text-gray-800"
              :for="item.title"
            >
              {{ item.title }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-11/12 mx-auto text-center text-grey my-10">
    <template v-if="!loading">
      <el-table
        header-row-class-name="bg-gray-200"
        header-cell-class-name="text-teal py-5 "
        :cell-style="{ padding: '10px' }"
        row-class-name="border-t"
        class="w-full rounded-xl overflow-hidden bg-gray-100"
        :data="allPolicies"
        style="width: 100%"
      >
        <el-table-column
          v-for="item in tableHeaders"
          :key="item.index"
          :prop="item.prop"
          :label="item.title"
        >
          <template #default="scope">
            <div class="" v-if="item.id === 'date'">
              {{ new Date(scope.row.created_at).toLocaleDateString("en-GB") }}
            </div>
            <div class="" v-if="item.id === 'role'">
              {{ rolesDefiner(scope.row.roles) }}
            </div>
            <div class="" v-if="item.id === 'type'">
              {{ scope.row.url.slice(scope.row.url.length - 3) }}
            </div>
            <div class="" v-if="item.id === 'actions'">
              <tableOptionsDropdown
                :show="showDropDown"
                :id="scope.row.id"
                :options="dropdownOptions"
                :dynamicParam="scope.row.id"
                @close="showDropDown = !showDropDown"
                @click="showDropDown = !showDropDown"
              >
                <span class="material-icons"> more_horiz </span>
              </tableOptionsDropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <table-skeleton></table-skeleton>
    </template>
  </div>
  <createNewPolicyModal
    :secondaryOptions="getRoles"
    v-model:showModal="showModal"
    :title="'Add New IT policy'"
    :text="'it-policies'"
  />
</template>
