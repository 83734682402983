<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import BaseModal from "@/components/ui/baseComponents/modal.vue";
import Dropzone from "./dropzone.vue";
import { useStore } from "vuex";

const store = useStore();
const emits = defineEmits(["update:showModal"]);
const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  secondaryOptions: {
    type: [Array, Object],
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
});
const showModalComputed = computed({
  get() {
    return props.showModal;
  },
  set(value) {
    files.value = null;
    selectedRoles.value = [];
    details.value = "";
    policyType.value = "";
    errorFlag.value = false;
    docFlag.value = false;
    emits("update:showModal", value);
  },
});
var files = ref(null);
const selectedRoles = ref([]);
var details = ref("");
var policyType = ref("");
var errorFlag = ref(false);
var detailFlag = ref(false);
var docFlag = ref(false);
const dropdownData = [
  {
    customLabel: "Health and Safety",
    value: "health-and-safety",
  },
  {
    customLabel: "HR & Training",
    value: "hr-and-training",
  },
  {
    customLabel: "Clinical Governance",
    value: "clinical-governance",
  },
  {
    customLabel: "Admin",
    value: "admin",
  },
];
const submit = async () => {
  if (
    selectedRoles.value === [] ||
    details.value === "" ||
    policyType.value === ""
  ) {
    errorFlag.value = true;
    return;
  } else {
    errorFlag.value = false;
  }
  if (details.value.length > 999) {
    detailFlag.value = true;
    return;
  } else {
    detailFlag.value = false;
  }
  if (files.value === null) {
    docFlag.value = true;
    return;
  } else {
    docFlag.value = false;
  }
  const form = new FormData();
  form.append(
    props.text === "it-policies" ? "it_policy" : "handbook",
    files.value[0]
  );
  //   form.append("roles", JSON.stringify(formRoles));
  selectedRoles.value?.map((item, index) => {
    form.append(`roles[${index}][role]`, item);
  });
  form.append("detail", details.value);
  form.append("type", policyType.value.value);
  const { data } = await store.dispatch("RePolicies/storeHandbookOrItPolicy", {
    endpoint: props.text,
    form,
  });
  if (data.success) {
    emits("success");
    selectedRoles.value = [];
    policyType.value = [];
    details.value = "";
    emits("update:showModal", false);
  }
};
</script>
<template>
  <BaseModal v-model:show="showModalComputed" @confirm="submit">
    <template #title> {{ title }} </template>
    <template #content>
      <div class="my-1 px-5">
        <div class="flex gap-5">
          <div class="border-r flex-grow">
            <p class="text-lg mb-3 font-medium">Applied to*:</p>
            <div class="flex flex-col h-48 overflow-auto shadow-md">
              <template v-for="item in secondaryOptions" :key="item.code">
                <div class="flex">
                  <input
                    :value="item.code"
                    v-model="selectedRoles"
                    :id="item.code"
                    type="checkbox"
                    name="checked-demo"
                    class="form-tick appearance-none cursor-pointer bg-white bg-check h-6 w-6 border border-grey rounded-md checked:bg-teal checked:border-transparent focus:outline-none"
                  />
                  <label
                    :for="item.code"
                    class="space-x-3 mb-3 ml-3 cursor-pointer"
                  >
                    <span class="font-normal"> {{ item.label }} </span>
                  </label>
                </div>
              </template>
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="flex flex-col flex-grow">
              <v-select
                v-model="policyType"
                name="policyType"
                class="style-chooser-site-name"
                placeholder="Choose Policy Type*"
                label="customLabel"
                :options="dropdownData"
              ></v-select>
              <div class="flex flex-col flex-grow">
                <p><b>Description*:</b></p>
                <textarea
                  v-model="details"
                  name="description"
                  class="w-full h-full p-3 border rounded-md shadow-md"
                  maxlength="999"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <Dropzone v-model:files="files" />
        <span v-if="errorFlag === true" class="text-red"
          >Input required fields.</span
        >
        <span v-if="docFlag === true" class="text-red"
          >Upload policy document.</span
        >
        <span v-if="detailFlag === true" class="text-red"
          >Description should be less than 1000 chars.</span
        >
      </div>
    </template>
    <template #successButton> Submit </template>
  </BaseModal>
</template>
