<script setup>
import { ref, inject, defineEmits } from "vue";
import { useDropzone } from "vue3-dropzone";

const emits = defineEmits(["update:files"]);

const toast = inject("toast");
const accaptedFiles = ref(null);
function onDrop(acceptFiles, rejectReasons) {
  accaptedFiles.value = acceptFiles;
  emits("update:files", acceptFiles);
  if (rejectReasons) {
    rejectReasons.map((item) => {
      toast.show(item.errors[0].message);
    });
  }
  console.log(rest)
}

const { getRootProps, getInputProps, rest } = useDropzone({ onDrop, maxFiles: 1, accept: ['.pdf', '.docx'] });
</script>
<template>
  <div>
    <div
      class="bg-white border-dashed border my-4 text-center py-5 cursor-pointer shadow-md"
    >
      <div v-bind="getRootProps()">
        <input v-bind="getInputProps()" />
        <div>
          <div class="text-5xl material-icons text-gray-300">file_upload</div>
          <p v-if="!accaptedFiles">
            Drag 'n' drop some files here, or click to select files
          </p>
          <div v-if="accaptedFiles">
            <p v-for="item in accaptedFiles" :key="item.name">
              {{ item.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
